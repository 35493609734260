<template lang="pug">
  v-container
    v-row
      v-col(cols="1" v-if="voucherId").pt-5
        v-btn(
          dark
          block
          color="green"
          @click="$router.push({ name: 'vouchers.petty-cash.create', params: { voucherId: voucherId } })"
        )
          span new
      v-col(cols="2")
        v-autocomplete(
          :items="voucherIndexList"
          item-value="id"
          item-text="voucher_no"
          v-model="voucherId"
          no-filter
          label="Voucher"
          :search-input.sync="searchVoucher"
          clearable
          :loading="voucherIndexGetting"
        )
    pt-cash-voucher-table(
      ref="ptCashVoucherTable"
      @show="showPtCash"
    )
    pt-cash-voucher-show(
      v-model="showPtCashVoucher"
      @approve="approvePtCash"
      @reject="rejectPtCash"
      :pt-cash-voucher-id="ptCashVoucherId"
    )
</template>
<script>
import voucherRepository from '@/repositories/voucher.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [voucherIndexVars, voucherIndexVarNames] = requestVars({ identifier: 'voucher-index' })
const voucherIndexHandler = new VueRequestHandler(null, voucherIndexVarNames)

export default {
  name: 'PettyCashVouchers',
  components: {
    ptCashVoucherTable: () => import('./Table'),
    ptCashVoucherShow: () => import('./Show'),
  },
  data () {
    return {
      ...voucherIndexVars,
      searchVoucher: null,
      keepVoucher: null,
      showPtCashVoucher: false,
      ptCashVoucherId: null,
    }
  },
  created () {
    this.getVoucherIndex()
  },
  computed: {
    voucherId: {
      get () {
        if (!this.$route.params.voucherId) return null
        return Number(this.$route.params.voucherId)
      },
      set (val) {
        if (!val) {
          this.$router.push('/vouchers/petty-cash')
          return
        }
        this.$router.push(`/vouchers/${val}/petty-cash`)
      },
    },
  },
  watch: {
    searchVoucher () {
      this.getVoucherIndex()
    },
  },
  methods: {
    showPtCash (ptCashVoucherId) {
      this.showPtCashVoucher = true
      this.ptCashVoucherId = ptCashVoucherId
    },
    setKeepVoucher () {
      this.searchVoucher = this.voucher.voucher_no
    },
    getVoucherIndex () {
      const handler = voucherIndexHandler
      const repository = voucherRepository.index
      const params = this.voucherIndexParams()
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    voucherIndexParams () {
      return {
        hasCount: false,
        search: this.searchVoucher,
      }
    },
    approvePtCash (id) {
      this.$refs.ptCashVoucherTable.approvePtCash(id)
    },
    rejectPtCash (id) {
      this.$refs.ptCashVoucherTable.rejectPtCash(id)
    },
  },
}
</script>